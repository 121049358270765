#footer-main-container{
    display: flex;
    flex-direction: column;
    height:auto;
}
#lol{
    height:45rem !important;
}
#lol-mobile{
    height:55.5rem !important;
}