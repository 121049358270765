#about-our-firm-container{
    background-color:transparent;
    color:#FFFDFD;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height:40rem;
}
#about-our-firm-image{
    width:25rem;
    height:20rem;
    background-position: center;
    background-size: cover;
    background-color:transparent;
}
#about-our-firm-text-container{
    background-color:transparent;
    width:35rem;
    height:auto;
}
#about-our-firm-text-container p{
    line-height:2rem;
}

/* MOBILE */
#about-our-firm-container-mobile{
    background-color:transparent;
    color:#FFFDFD;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height:40rem;
}
#about-our-firm-image-mobile{
    width:25rem;
    height:20rem;
    background-color:transparent;
}
#about-our-firm-text-container-mobile{
    height:auto;
    padding-right:1rem;
    padding-left:1rem;
}
#about-our-firm-text-container-mobile p{
    line-height:2rem;
    font-size:1rem;
    text-align: center;
}
#about-our-firm-text-container-mobile h1{
    line-height:2rem;
    font-size:2rem;
    text-align: center;
}