#about-biography-container{
    height:auto;
    padding-top:8rem;
    padding-bottom:8rem;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
#about-biography-content-container{
    display:flex;
    width:60rem;
    justify-content: space-evenly;
    align-items: center;
}
#about-biography-image{
    background-color:transparent;
    width:25rem;
    height:47.5rem;
    background-position: center;
    background-size: cover;
}
#about-biography-text-container{
    color:white;
    height:auto;
    width:30rem;
    position: relative;
}
#about-biography-text-container div p{
    font-size:1rem;
    margin-top:0.5rem;
    margin-bottom:0.5rem;
}
/*NOBLE*/
#about-biography-container-mobile{
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}
#about-biography-content-container-mobile{
    width:auto;
    height:inherit;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: transparent;
    background-position: center !important;
    background-size: cover;
    padding-top:3rem;
    padding-bottom:3rem;
    align-items: center;
}
#about-biography-text-container-mobile{
    color:white;
    height:auto;
    background-color:transparent;
    padding-right:3rem;
    padding-left:3rem;
    width:inherit;
    position: relative;
}
#about-biography-text-container-mobile div p{
    font-size:.8rem;
    margin-top:0.5rem;
    margin-bottom:0.5rem;
}
#about-biography-text-container-mobile div h1{
    font-size:1.5rem;
    margin-top:0.5rem;
    margin-bottom:0.5rem;
}