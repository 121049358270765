#about-practice-areas-container{
    width:100%;
    height:auto;
    background-color:white;
    padding-top:5rem;
    padding-bottom:5rem;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
#about-practice-area-text-container{
    color:#021B3C;
    background-color: transparent;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:50rem;
}
#about-practice-area-text-container h1{
    margin-bottom:0.5rem !important;
}
#about-practice-list-container{
    background-color: transparent;
    display: flex;
    width:inherit;
    justify-content: space-between;
}
#about-practice-list-container ul{
    
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:1rem;
    padding-left: 0;
    background-color:transparent;
    line-height: 3.5rem;
    font-size:1.5rem;
}
#about-practice-list-container ul li{
    list-style: none;
}
#info{
    letter-spacing: .3rem;
}

/*MOBILE*/
#about-practice-areas-container-mobile{
    width:100%;
    height:auto;
    background-color:white;
    padding-top:8rem;
    padding-bottom:8rem;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
#about-practice-area-text-container-mobile{
    color:#021B3C;
    background-color: transparent;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:50rem;
}
#about-practice-area-text-container-mobile h1{
    margin-bottom:0.5rem !important;
    font-size:1.9rem;
}
#about-practice-list-container-mobile{
    background-color: transparent;
    display: flex;
    width:inherit;
    justify-content: space-between;
}
#about-practice-list-container-mobile ul{
    
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:1rem;
    padding-left: 0;
    background-color:transparent;
    line-height: 3.5rem;
}
#about-practice-list-container-mobile ul li{
    list-style: none;
    font-size:1.15rem;
}
#info-mobile{
    letter-spacing: .2rem;
    font-size:.9rem;
}