#home-practice-area-container{
    background-color:transparent;
    display:flex;
    flex-direction: row;
    height:40rem;
}
#home-practice-area-text-container{
    background-color:transparent;
    display:flex;
    flex-direction: column;
    justify-content:center;
    width:65vw;
}
#home-practice-area-text{
    background-color:transparent;
    color:white;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    width:auto;
}
#home-practice-area-text button{
    font-size:1rem;
}
#home-practice-area-text h1{
    font-size:3rem;
}
#home-practice-area-text li{
    line-height: 4rem;
    letter-spacing: .3rem;
    font-size:1.5rem;
    padding-left:0 !important;
    list-style: none !important;
}
#home-practice-area-text ul{
    padding-left:0 !important;
    text-align:center;
    margin-bottom:0;
}
#home-practice-area-image{
    background-color:transparent;
    width:35vw;
    background-position:center;
    background-size: cover;
}


/*MOBILE*/


#home-practice-area-container-mobile{
    display:flex;
    justify-content: center;
    height:auto;
    padding-top:5rem;
    padding-bottom:10rem;
}
#home-practice-area-text-container-mobile{
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content:center;
}
#home-practice-area-text-mobile{
    background-color:transparent;
    color:white;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    width:auto;
}
#home-practice-area-text-mobile h1{
    font-size:2rem;
}
#home-practice-area-text-mobile li{
    line-height: 3rem;
    letter-spacing: .3rem;
    font-size:1rem;
    padding-left:0 !important;
    list-style: none !important;
}
#home-practice-area-text-mobile ul{
    padding-left:0 !important;
}
#home-practice-area-text-mobile button{
    font-size:1rem;
}
