#header-two-container{
    height:100vh !important;
    display:flex;
    flex-direction:row;
    width:100% !important;
    background-color:transparent;
}
#header-two-text-container{
    color:#EFEFEF;
    background-color:transparent;
    width:65vw !important;
    display:flex;
    flex-direction: column;
    justify-content: center !important;
    align-items:center !important;
}
#header-two-text h1{
    line-height: 1;
    font-size:4rem;
}
#header-two-text-container div{
    font-size:1rem;
    text-align:center;
    background-color:transparent;
}
#header-two-title{
    color:#EFEFEF;
    background-color:transparent !important;
    width:auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}
#over-big{
    display:flex;
    flex-direction: row;
}

#header-two-image{
    background-color:transparent;
    height:100%;
    width:35vw;
    background-position:center;
    background-size: cover;
}
.contact-us{
    background-image:url("../../assets/contact_us.jpg");
}
.about-us{
    background-image:url("../../assets/about_us.jpg");
}
.privacy-policy{
    background-image:url("../../assets/privacy.jpg");
}
/*MOBILE*/

#header-two-container-mobile{
    height:100vh !important;
    display:flex;
    flex-direction:row;
    justify-content: center;
    width:100% !important;
}
#header-two-text-container-mobile{
    color:#EFEFEF;
    display:flex;
    flex-direction: column;
    justify-content: center !important;
    align-items:center !important;
}
#header-two-text-mobile h1{
    line-height: 1;
    font-size:2.5rem;
}
#header-two-text-container-mobile div{
    font-size:.8rem;
    text-align:center;
    background-color:transparent;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#header-two-title-mobile{
    color:#EFEFEF;
    background-color:transparent !important;
    width:auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}
#over-big-mobile{
    display:flex;
    flex-direction: row;
}
#header-two-image-mobile{
    background-color:transparent;
    height:100%;
    width:35vw;
}