#information-bar-container{
    width:100%;
    height:15rem;
    background-color:white;
    display:flex;
    justify-content: space-around;
    align-items:center;
}
.information-item-container h1{
    font-size:1.5rem;
    letter-spacing:.5rem;
}
.information-item-container{
    width:25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.information-item-container:hover{
    cursor: pointer !important;
}
.information-item-container p{
    font-size:1rem;
    margin-bottom:0;
    letter-spacing:.2rem;
}
a{
    color:#121212 !important;
}
a:hover{
    text-decoration: none !important;
}
#no-hover:hover{
    cursor:default !important;
}   

/*MOBILE*/
#information-bar-container-mobile{
    width:100%;
    height:25rem;
    background-color:white;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items:center;
}
.information-item-container-mobile h1{
    font-size:1.5rem;
    letter-spacing:.5rem;
}
.information-item-container-mobile{
    width:auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.information-item-container-mobile:hover{
    cursor: pointer !important;
}
.information-item-container-mobile p{
    font-size:.8rem;
    margin-bottom:0;
    letter-spacing:.2rem;
}
a{
    color:#121212 !important;
}
a:hover{
    text-decoration: none !important;
}