#copy-right-container{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    margin-top:11rem;
    width:100%;
    height:8rem;
}
#copy-right-container h1{
    color:white;
    letter-spacing: .2em;
    font-size:1.4rem;
}
#copy-right-container-mobile{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    width:100%;
    height:8rem;
}
#copy-right-container-mobile h1{
    color:white;
    letter-spacing: .2em;
    font-size:.8rem;
}