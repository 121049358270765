*{
    font-family: 'Poppins', sans-serif;
}
.button-light{
    background-color:transparent;
    border:3px transparent solid;
    letter-spacing: 10px;
    padding:.3rem 1rem;
    font-size:1.3rem;
    color:white;
    z-index: 50;
}
.thin{
    font-weight:100;
}
.light{
    font-weight:300;
}
.regular{
    font-weight:400;
}
.medium{
    font-weight:500;
}
.heavy{
    font-weight:800;
}
.medium-divider{
    margin-top:1.2rem;
    margin-bottom:1.3rem;
    width:15rem;
    height:1px;
    background-color: #021B3C;
}
span{
    color:#2C75FF;
}
#container-fluid-body{
    padding:0;
    overflow: hidden !important;
}