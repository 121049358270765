#contact-forms-container{
    background-color:transparent;
    width:100%;
    height:40rem;

    display:flex;
    justify-content: space-evenly;
    align-items: center;
}
#contact-forms-image{
    background-color:transparent;
    height:20rem;
    width:20rem;
    background-size: cover;
    background-position: center;
}
#contact-forms-content{
    background-color:transparent;
    width:40rem;
}
#contact-forms{
    display:flex;
    flex-direction: column;
    width:auto;
}
#contact-forms input{
    border: none;
    margin-top:1rem;
    padding-bottom:.3rem;
    padding-top:.3rem;
    color:white;
    border-bottom: 1px solid white;
    background-color:transparent;
    transition:.3s ease-in-out;
    letter-spacing: .1rem;
}
#contact-forms-content h1{
    color:white;
}
#contact-forms input:focus{
    padding-bottom:.3rem;
    padding-left:1rem;
    outline: none !important;
    color:#121212;
    background-color:white;
}
#contact-forms textarea{
    border: none;
    margin-top:1rem;
    padding-bottom:.3rem;
    color:white;
    border-bottom: 1px solid white;
    background-color:transparent;
    transition:.3s ease-in-out !important;
    height:2rem;
    letter-spacing: .1rem;
}
#contact-forms textarea:focus{
    transition:.3s ease-in-out !important;
    border: none;
    margin-top:1rem;
    padding-bottom:.3rem;
    padding-left:1rem;
    border-bottom: 1px solid white;
    color:#121212;
    background-color:white;
    height:auto;
    outline:none!important;
}
#contact-form-submit{
    letter-spacing: .5rem !important;
    transition: .3s ease-in-out;
    border:none !important;
    width:auto;
    margin:auto;
}
#contact-form-submit:hover{
    color:#2C75FF;
}














/* MOBILE */
#contact-forms-container-mobile{
    background-color:transparent;
    width:100%;
    padding-left:3rem;
    padding-right:3rem;
    height:40rem;
    display:flex;
    justify-content: space-evenly;
    align-items: center;
}
#contact-forms-image-mobile{
    background-color:transparent;
    height:20rem;
    width:20rem;
    background-size: cover;
    background-position: center;
}
#contact-forms-content-mobile{
    background-color:transparent;
    width:40rem;
}
#contact-forms-mobile{
    display:flex;
    flex-direction: column;
    width:auto;
}
#contact-forms-mobile input{
    border: none;
    margin-top:1rem;
    padding-bottom:.3rem;
    padding-top:.3rem;
    color:white;
    border-bottom: 1px solid white;
    background-color:transparent;
    transition:.3s ease-in-out;
    letter-spacing: .1rem;
}
#contact-forms-content-mobile h1{
    color:white;
    font-size:2rem;
}
#contact-forms-mobile input:focus{
    padding-bottom:.3rem;
    padding-left:1rem;
    outline: none !important;
    color:#121212;
    background-color:white;
}
#contact-forms-mobile textarea{
    border: none;
    margin-top:1rem;
    padding-bottom:.3rem;
    color:white;
    border-bottom: 1px solid white;
    background-color:transparent;
    transition:.3s ease-in-out !important;
    height:2rem;
    letter-spacing: .1rem;
}
#contact-forms-mobile textarea:focus{
    transition:.3s ease-in-out !important;
    border: none;
    margin-top:1rem;
    padding-bottom:.3rem;
    padding-left:1rem;
    border-bottom: 1px solid white;
    color:#121212;
    background-color:white;
    height:auto;
    outline:none!important;
}
#contact-form-submit-mobile{
    letter-spacing: .5rem !important;
    transition: .3s ease-in-out;
    border:none !important;
    width:auto;
    margin:auto;
}
#contact-form-submit-mobile:hover{
    color:#2C75FF;
}