#header-one-container{
    height:100vh !important;
    display:flex;
    flex-direction:row;
    width:100% !important;
    background-color:transparent;
}
#header-one-text-container{
    color:white;
    background-color:transparent;
    width:65vw !important;
    display:flex;
    flex-direction: column;
    justify-content: center !important;
    align-items:center !important;
}
#header-one-text h1{
    line-height: 1;
    font-size:4rem;
}
#header-one-text-container div{
    font-size:.8rem;
    text-align:center;
    background-color:transparent;
}
#header-one-title{
    color:white;
    background-color:transparent !important;
    width:auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}
#over-big{
    display:flex;
    flex-direction: row;
}

#header-one-image{
    background-color:transparent;
    height:100%;
    width:35vw;
    background-position:center;
    background-size:cover ;
}
#header-one-text-container img{
    height:20rem;
    width:20rem;
}
#header-one-text p{
    font-size:1rem;
}


/*mobile*/

#header-one-container-mobile{
    height:100vh !important;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    width:100% !important;
}
#header-one-text-container-mobile{
    width:auto !important;
    height:auto;
    display:flex;
    flex-direction: column;
    justify-content: center !important;
    align-items:center !important;
}
#header-one-text-mobile h1{
    color:white !important;
}
#header-one-text-mobile{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#header-one-text-mobile p{
    font-size:.8rem;
    letter-spacing:.4rem;
    text-align:center;
}
#header-one-text-container-mobile div{
    font-size:.8rem;
    text-align:center;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#header-one-title-mobile{
    background-color:transparent !important;
    width:auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}
#header-one-text-container-mobile img{
    height:10rem;
    position:absolute;
    width:10rem;
}