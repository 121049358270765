#privacy-content-container{
    background-color:transparent;
}
#privacy-content-text-container{
    padding:7rem;
}
#privacy-content-text-container p{
    font-size:1.1rem;
    color:white;
    line-height:2rem;
}
#privacy-content-text-container a{
    font-size:1.1rem;
    color:white !important;
    line-height:2rem;
    text-decoration: underline;
}
#privacy-content-text-container ul{
    font-size:1.1rem;
    color:white;
    line-height:2rem;
    
}
#privacy-content-text-container ul li{
    font-size:1.1rem;
    color:white;
}
#privacy-content-text-container h1{
    font-size:1.1rem;
    color:white;
    line-height:2rem;
    margin-top:2rem;
    margin-bottom:0!important;
}

/*mobile*/
#privacy-content-container-mobile{
    background-color:transparent;
}
#privacy-content-text-container-mobile{
    padding:7rem 1.5rem;
}
#privacy-content-text-container-mobile p{
    font-size:.8rem;
    color:white;
    line-height:2rem;
}
#privacy-content-text-container-mobile a{
    font-size:.8rem;
    color:white !important;
    line-height:2rem;
    text-decoration: underline;
}
#privacy-content-text-container-mobile ul{
    font-size:.8rem;
    color:white;
    line-height:2rem;
    
}
#privacy-content-text-container-mobile ul li{
    font-size:.8rem;
    color:white;
}
#privacy-content-text-container-mobile h1{
    font-size:1.1rem;
    color:white;
    line-height:2rem;
    margin-top:2rem;
    margin-bottom:0!important;
}