#nav-bar{
    transition: ease-in-out .5s;
    font-size:1.2rem;
    position: fixed;
    height:6rem;
    display:flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    z-index:1000;
}
.nav-bar-item{
    transition:ease-in-out .5s !important;
    letter-spacing:5px;
    color:white;
    list-style: none;
}
#nav-list{
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    width:50rem;
    padding:0;
    margin:0;
}
.transparent{
    background-color:#121212;
}
.transparent .nav-bar-item{
    color:white !important;
}
.transparent .nav-bar-item:hover{
    color:#2C75FF !important;
}
.fill{
    background-color:white;
}
.fill .nav-bar-item{
    color:#121212!important;
}
.fill .nav-bar-item:hover{
    color:#2C75FF  !important;
}
#logo-test{
    height:4.5rem;
    width:4.5rem;
    position:fixed;
    top:1rem;
    left:2.5rem;
    transition: ease-in-out .3s;
}
.jes{
    color:white !important;
    background:white !important;
}



#nav-bar-mobile{
    transition: ease-in-out .5s;
    font-size:1.2rem;
    position: fixed;
    top:0;
    left:-15rem;
    align-items: center;
    z-index:1000;
    display:flex;
    flex-direction: column;
    justify-content: center;
    height:100%;
    width:15rem;
    background-color:white;
}
.nav-content-mobile{
    width:15rem;
    display:flex;
    justify-content:center;
}
.nav-bar-item-mobile{
    text-align:center;
    margin-top:.2rem;
    margin-bottom:.2rem;
    transition:ease-in-out .3s;
} 
.nav-bar-item-mobile:hover{
    color:#2C75FF !important;
} 
#logo-test-mobile{
    width:6rem;
    height:6rem;
    top:5rem;
    left:4rem;
    position:absolute;
}
#exit-icon{
    position:absolute;
    top:1rem;
    width:2rem;
    height:2rem;
    right:1rem;
    transition:ease-in-out 1s;
}
#exit-icon:hover{
    color:#2C75FF !important;
    cursor:pointer;
}
#enter-icon-container:hover{
    background-color:#2C75FF !important;
}
#enter-icon-container:hover svg{
    color:white !important;
}
#enter-icon{
    color:black!important;
    position:relative;
    height:1.5rem;
    width:1.5rem;
    transition:ease-in-out 1s;
}
#enter-icon-container{
    transition: ease-in-out .3s;
    cursor:pointer !important;
    top:1rem;
    left:1rem;
    border-radius:4rem;
    position:fixed;
    display:flex;
    background-color:white;
    padding:.5rem;
    z-index:10;
}


.stop-scrolling{
    height:100% !important;
    overflow: hidden;
}